import { createTheme, Theme } from '@mui/material/styles';

const on3Theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#FE3B1F',
    },
    secondary: {
      main: '#1D252C',
    },
    error: {
      main: '#ff1744',
    },
    background: {
      default: '#f8f9fc',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: '#2196F3',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: '#FE3B1F',
          },
        },
        track: {
          '&.Mui-checked + &': {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: '#F4EFDC',
          },
        },
      },
    },
  },
});

export { on3Theme };
export default on3Theme;
