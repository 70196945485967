import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { FC, SetStateAction, useCallback } from 'react';

import styles from './ConfirmModal.module.scss';

interface IConfirmModalProps {
  onConfirm?: () => void;
  open: boolean;
  setOpen: (value: SetStateAction<boolean>) => void;
  title?: string;
}
export const ConfirmModal: FC<IConfirmModalProps> = ({
  title,
  onConfirm,
  open,
  setOpen,
}) => {
  const callSetOpen = useCallback(() => setOpen(false), [setOpen]);
  const onClickHandler = useCallback(() => {
    setOpen(false);
    onConfirm?.();
  }, [onConfirm, setOpen]);

  return (
    <Dialog
      aria-labelledby="confirm-dialog"
      className={styles.container}
      onClose={callSetOpen}
      open={open}
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogActions>
        <Button onClick={callSetOpen} variant="contained">
          No
        </Button>
        <Button onClick={onClickHandler} variant="contained">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
