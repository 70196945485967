// eslint-disable-next-line import/no-unassigned-import
import 'src/styles/layout.scss';

import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import type { IUser } from '@on3/ui-lib/src/api/schema/custom-contracts';
import { AuthProvider } from '@on3/ui-lib/src/contexts/AuthProvider';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useCallback, useEffect, useState } from 'react';
import { ModalProvider } from 'src/components/Contexts/ModalProvider';
import { ToastProvider } from 'src/components/Contexts/ToastProvider';
import Loading from 'src/components/Shared/Loading';
import { createEmotionCache } from 'src/styles/createEmotionCache';
import on3Theme from 'src/styles/theme';

const clientSideEmotionCache = createEmotionCache();

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const MyApp = (props: MyAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const { a: userAlias, uid: userId }: IUser = pageProps?.userData || {};

  const handleStart = useCallback(
    (url: string) => {
      if (url !== router.asPath) {
        setIsLoading(true);
      }
    },
    [router?.asPath],
  );
  const handleComplete = useCallback(() => setIsLoading(false), []);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('hashChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('hashChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [handleComplete, handleStart, router]);

  const setDimensions = `
    gtag('set', {
      'custom_map':
        {
          'dimension1': 'userId',
          'dimension2': 'userAlias'
        }
    });
  `;

  const gaScript = `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          ${setDimensions}
          gtag('config', 'UA-214991326-1', {
            send_page_view: false
          });
          window.gtag('event', 'page_view', {
            page_path: window.location.pathname,
            'userId': '${userId}',
            'userAlias': '${userAlias}'
          });`;

  return (
    <AuthProvider>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=UA-193678100-1"
        strategy="lazyOnload"
      />
      <Script id="google-analytics" strategy="lazyOnload">
        {gaScript}
      </Script>
      <Head>
        <title>On3.com</title>
        <meta
          content="minimum-scale=1, initial-scale=1, width=device-width"
          name="viewport"
        />
      </Head>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={on3Theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {isLoading && <Loading />}
          <ModalProvider {...pageProps}>
            <ToastProvider {...pageProps}>
              <Component {...pageProps} />
            </ToastProvider>
          </ModalProvider>
        </ThemeProvider>
      </CacheProvider>
    </AuthProvider>
  );
};

export default MyApp;
