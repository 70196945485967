import axios, { InternalAxiosRequestConfig } from 'axios';
import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();

const externalUrls = {
  local: `https://api.dev.on3.com/`,
  development: `https://api.dev.on3.com/`,
  qa: `https://api.qa.on3.com/`,
  production: `https://api.on3.com/`,
} as const;

const adminUrls = {
  local: `https://db.dev.on3.com`,
  development: `https://db.dev.on3.com`,
  qa: `https://db.qa.on3.com`,
  production: `https://db.on3.com`,
} as const;

const cmsUrls = {
  local: `https://admin.dev.on3.com`,
  development: `https://admin.dev.on3.com`,
  qa: `https://admin.qa.on3.com`,
  production: `https://admin.on3.com`,
} as const;

const webUrls = {
  local: `https://www.dev.on3.com`,
  development: `https://www.dev.on3.com`,
  qa: `https://www.qa.on3.com`,
  production: `https://www.on3.com`,
} as const;

type Environment = keyof typeof adminUrls;

const nextPublicAppEnv: Environment =
  (process.env.NEXT_PUBLIC_APP_ENV as Environment) || 'local';

const databaseUrl = adminUrls[nextPublicAppEnv];
const cmsUrl = cmsUrls[nextPublicAppEnv];
const webUrl = webUrls[nextPublicAppEnv];

const externalApi = axios.create({
  baseURL: externalUrls[nextPublicAppEnv],
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 20000,
});

const internalApi = axios.create({
  baseURL: serverRuntimeConfig?.internalApiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 20000,
});

const proxyApi = axios.create({
  baseURL: serverRuntimeConfig?.proxyApiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 5000,
});

const proxyApiWithVary = axios.create({
  baseURL: serverRuntimeConfig?.proxyApiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 20000,
});

const getFulfilledValue = <T>(data: PromiseSettledResult<T>): T | null => {
  return data.status === 'fulfilled' ? data.value : null;
};

const swrFetcher = <T>(url: string, params?: Record<string, unknown>) =>
  externalApi.get<T>(url, { params }).then((r) => r.data);

if (process.env.NEXT_RUNTIME === 'nodejs') {
  import('@opentelemetry/api').then(({ context, propagation }) => {
    const onFulfilled = (value: InternalAxiosRequestConfig<unknown>) => {
      propagation.inject(context.active(), value.headers);

      return value;
    };

    externalApi.interceptors.request.use(onFulfilled);
    internalApi.interceptors.request.use(onFulfilled);
    proxyApi.interceptors.request.use(onFulfilled);
    proxyApiWithVary.interceptors.request.use(onFulfilled);
  });
}

export {
  cmsUrl,
  databaseUrl,
  externalApi,
  getFulfilledValue,
  internalApi,
  proxyApi,
  proxyApiWithVary,
  swrFetcher,
  webUrl,
};
