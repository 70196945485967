import type { FC } from 'react';

import Loader from '../SVG/loader';
import styles from './Loading.module.scss';

const Loading: FC = () => (
  <div className={styles.spinner}>
    <Loader />
  </div>
);

export default Loading;
