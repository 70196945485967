import {
  Context,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ConfirmModal } from 'src/components/Form/ConfirmModal';

interface IFunctionProps {
  functionProps?: Record<string, unknown> | undefined;
}

interface IConfirmProps {
  (functionProps?: IFunctionProps): void | undefined;
}

interface IModalContext {
  confirm: ({
    title,
    onConfirm,
    functionProps,
  }: {
    title: string;
    onConfirm?: () => void;
    functionProps: Record<string, unknown>;
  }) => void;
}
const ModalContext: Context<IModalContext> = createContext({} as IModalContext);

interface ComponentProps {
  children: React.ReactNode;
}

export const ModalProvider = ({ children }: ComponentProps) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [functionProps, setFunctionProps] = useState<IFunctionProps>();
  const [onConfirm, setOnConfirm] = useState<IConfirmProps>();

  const confirm = useCallback(
    ({
      title: incomingTitle,
      onConfirm: incomingOnConfirm,
      functionProps: incomingFunctionProps,
    }: {
      title: string;
      onConfirm?: any;
      functionProps?: any;
    }) => {
      setTitle(incomingTitle);
      setOnConfirm(incomingOnConfirm);
      setFunctionProps(incomingFunctionProps);
      setOpen(true);
    },
    [],
  );

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm(functionProps);
    }
  }, [functionProps, onConfirm]);

  const providerValue = useMemo(() => ({ confirm }), [confirm]);

  return (
    <ModalContext.Provider value={providerValue}>
      {children}
      <ConfirmModal
        onConfirm={handleConfirm}
        open={open}
        setOpen={setOpen}
        title={title}
      />
    </ModalContext.Provider>
  );
};

export function useModal() {
  const context = useContext(ModalContext);

  return context;
}

export default useModal;
